import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const leftTdStyle = {
    margin: "0 auto",
    width: "200px",
}


const AboutPage = () => {
    return (

        <Layout>
            <Seo title="About"/>
            <div align="center">
                <h1>About us</h1>
                <h2>Company Profile</h2>
                <table>
                    <tr>
                        <td style={leftTdStyle}>社名</td>
                        <td>株式会社Ringing (英文名 Ringing Inc.)</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>所在地</td>
                        <td>〒222-0033 神奈川県横浜市港北区新横浜3丁目7-7 新横浜アリーナ通りビル3F-46番</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>代表取締役</td>
                        <td>小川 貴之</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>設立</td>
                        <td>2021年(令和3年) ７月28日</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>資本金</td>
                        <td>1000千円</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>事業内容</td>
                        <td>主にテレコミュニケーション分野むけのシステム開発・保守運用事業</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}></td>
                        <td>プログラミングスクール事業</td>
                    </tr>
                </table>
                <p></p>
                <h2>History</h2>
                <table>
                    <tr>
                        <td style={leftTdStyle}>2021年7月28日</td>
                        <td>株式会社Ringing 設立</td>
                    </tr>
                </table>
            </div>
        </Layout>
    )
}

export default AboutPage
